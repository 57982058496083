import React from 'react';
import '../Home.css';

function Home() {
  return (
    <>
        <h1>Click the Button For a Dad Joke.</h1>
        <span>These Jokes are sourced from Dad Jokes API.</span>
    </>
  )
}

export default Home